<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :buttonRemove="hasRemove" :filters="filters">
      <div slot="content-buttons">
        <Button _key="btnNfseCreate" title="Nfse" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="nfseCreate" :clicked="create" />
      </div>
    </ViewTemplateWithTable>
    <CancelNfse />
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import CancelNfse from "./CancelNfse.vue";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "NfseListView",
  components: {
    ViewTemplateWithTable,
    Confirmation,
    Alert,
    Modal,
    Button,
    CancelNfse,
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Nota Fiscal",
        title: "NFSe",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/tax-document/nfse/get-all",
        urlDeleteAllApi: "/api/v1/tax-document/nfse/cancel",
        showChecks: false,
        headerTable: [
          {
            title: "Número",
            field: "number",
            type: "text",
            iconSearch: false,
          },
          {
            title: "Emissão",
            field: "registeredDate",
            type: "dateTime",
            iconSearch: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "fullName",
            title: "Cliente",
            type: "link",
            routeName: "nfseUpdate",
            iconSearch: true,
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Pendente",
              },
              {
                classCss: "text-center badge badge-info",
                fieldComparison: "Em processamento",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Autorizada",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Concluída",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Cancelada",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Rejeitada",
              },
            ],
          },
          {
            title: "Nota",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            title: "Retenção ISS",
            field: "valueTax",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            title: "VALOR FINAL",
            field: "valueFinal",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            field: "situacao",
            ifFieldEqual: "Autorizada",
            typeButton: "default",
            button: {
              classIcon: "fa-regular fa-ban",
              type: "danger",
              size: "small",
              eventName: "cancelNfse",
            },
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
      },
      filters: [
        {
          type: "multiOptions",
          title: "Consultor",
          field: "Consultant",
          url: "/api/v1/human-resources/collaborator/select-all",
          propsParams: { isConsultant: true },
          value: "",
        },
        {
          type: "date",
          title: "Data Emissão",
          field: "RegisteredDate",
          value: "",
        },
        {
          type: "options",
          title: "Status",
          field: "StatusName",
          options: [
            { text: "Autorizada", value: "Autorizada" },
            { text: "Pendente", value: "Pendente" },
            { text: "Em processamento", value: "Em processamento" },
            { text: "Rejeitada", value: "Rejeitada" },
            { text: "Cancelada", value: "Cancelada" },
          ],
          value: [],
        },
        {
          type: "decimal",
          title: "Valor",
          field: "ValueFinal",
          valueStart: 0,
          valueEnd: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generic", ["selected"]),
    ...mapState("user", ["userLogged"]),
    hasRemove() {
      if (this.userLogged.user.userName == 'UserTemp') return true;
      return false;
    }
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["deleteAllApi"]),
    create() {
      this.$router.push({
        name: "nfseCreate",
      });
      this.removeLoading(["btnNfseCreate"]);
    },
  },
};
</script>
<style>
.nfse-progress {
  color: #4bb4e2;
}

.nfse-accepted {
  color: #007BFF;
}

.nfse-rejected {
  color: #FF5454;
}
</style>